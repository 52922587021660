import React, { useState } from "react";
import { useBreakpoint } from "gatsby-plugin-breakpoints";

import { toggler } from "../../constants";
import { useTranslation } from 'react-i18next';

const AutomatedLiveComponent = () => {
  const {t} = useTranslation();
  const breakpoints = useBreakpoint();
  const [state, setState] = useState([...toggler]);

  const [active, setActive] = useState(0);

  const Data = [
    {
      title: "Sécurité",
      list: [
        {
          title: `${t('lockdown_browser')}`,
          isLockdown: true,
          isWebApp: true,
          isExtension: true,
        },
        {
          title: `${t('audio_video_and_screen_recordings')}`,
          isLockdown: false,
          isWebApp: true,
          isExtension: true,
        },
        {
          title: `${t('artificial_intelligence')}`,
          isLockdown: false,
          isWebApp: true,
          isExtension: true,
        },
      ],
    },
    {
      title: `${t('administrator_side')}`,
      list: [
        {
          title: `${t('content_integrated_by_mereos')}`,
          isLockdown: false,
          isWebApp: true,
          isExtension: false,
        },
        {
          title: `${t('dedicated_account_manager')}`,
          isLockdown: true,
          isWebApp: true,
          isExtension: true,
        },
        {
          title: "Catalogue APIs",
          isLockdown: true,
          isWebApp: true,
          isExtension: true,
        },
        {
          title: "Résultats en instantané",
          isLockdown: true,
          isWebApp: true,
          isExtension: true,
        },
        {
          title: `${t('instant_fraud_report')}`,
          isLockdown: true,
          isWebApp: false,
          isExtension: true,
        },
        {
          title: `${t('maximum_scalability')}`,
          isLockdown: true,
          isWebApp: true,
          isExtension: true,
        },
        {
          title: `${t('integration_with_your_lms')}`,
          isLockdown: true,
          isWebApp: false,
          isExtension: true,
        },
        {
          title: `${t('no_integration_required')}`,
          isLockdown: false,
          isWebApp: true,
          isExtension: false,
        },
      ],
    },
    {
      title: `${t('candidate_side')}`,
      list: [
        {
          title: `${t('no_account_creation')}`,
          isLockdown: true,
          isWebApp: true,
          isExtension: true,
        },
        {
          title: `${t('support_available_24')}`,
          isLockdown: true,
          isWebApp: true,
          isExtension: true,
        },
        {
          title: `${t('accessible_at_any_time')}`,
          isLockdown: true,
          isWebApp: true,
          isExtension: true,
        },
        {
          title: `${t('accessible_anywhere')}`,
          isLockdown: true,
          isWebApp: true,
          isExtension: true,
        },
        {
          title: `${t('accessible_without_software')}`,
          isLockdown: true,
          isWebApp: true,
          isExtension: true,
        },
        {
          title: `${t('encrypted_data')}`,
          isLockdown: true,
          isWebApp: true,
          isExtension: true,
        },
        {
          title: `${t('low_flow')}`,
          isLockdown: true,
          isWebApp: true,
          isExtension: true,
        },
        {
          title: `${t('free_practice_test')}`,
          isLockdown: true,
          isWebApp: true,
          isExtension: true,
        },
      ],
    },
  ];

  const leftSliderHandler = () => {
    if (active !== 0) {
      setActive(active - 1);
    }
  };

  const rightSliderHandler = () => {
    if (active < 2) {
      setActive(active + 1);
    }
  };

  const DataAnalyser = (s) => {
    let data;
    if (s === "true") {
      data = (
        <div>
          <img
            src="https://d2lxkizvrhu4im.cloudfront.net/icons/check_icon_orange.svg"
            alt="tick"
            className="m-0 w-4"
          />
        </div>
      );
    } else {
      data = (
        <div>
          <img
            src="https://d2lxkizvrhu4im.cloudfront.net/icons/cancel_mark_icon.svg"
            alt="cancel"
            className="m-0 w-4"
          />
        </div>
      );
    }

    return data;
  };

  const stateHandler = (index) => {
    const array = [...state];
    for (let i = 0; i < state.length; i++) {
      array[i].state = false;
    }
    array[index].state = !state[index].state;
    setState([...array]);
  };

  return (
    <>
      <div
        className="bg-fill w-full bg-cover bg-no-repeat h-full pt-48 md:pt-48 pb-20 md:pb-36 font-sans relative "
        style={{
          backgroundImage: `url("https://d2lxkizvrhu4im.cloudfront.net/images/automated_comp_bg.svg")`,
        }}
      >
        <div className="flex flex-col justify-center text-center w-11/12 mx-auto lg:w-10/12">
          <div className="md:mx-auto  text-center text-white space-y-2 md:space-y-4">
            <div className="text-3xl md:text-5xl   font-semibold">
              {t('offers_tailored_to_every_need')}
            </div>
            <div className="text-sm md:text-base  md:w-4/5 mx-auto">
              {t('none_of_our_solutions_require_the_presence')}
            </div>
          </div>
        </div>
        {/* mobile view */}
        <div className="md:hidden flex flex-col items-center space-y-4 w-full my-4 md:my-10  rounded-xl overflow-hidden ">
          <div className="flex flex-row space-x-6 w-full">
            <div
              className="bg-white bg-opacity-30  text-black  rounded-r-xl w-1/6 cursor-pointer"
              onClick={() => {
                leftSliderHandler();
              }}
            ></div>
            <div className="flex flex-col space-y-3 justify-center w-4/6 items-center bg-white text-black py-5  rounded-xl">
              <div>
                <img
                  src={`https://d2lxkizvrhu4im.cloudfront.net/icons/${state[active].iconSrc}`}
                  alt="thumbs up icon"
                  className="m-0 h-10"
                />
              </div>
              <div className="text-xl font-semibold">{state[active].name}</div>
            </div>

            <div
              className="bg-white bg-opacity-30 text-black  rounded-l-xl w-1/6 cursor-pointer"
              onClick={() => {
                rightSliderHandler();
              }}
            ></div>
          </div>
          <div className="flex flex-row space-x-6 ">
            <div
              className={`${
                active === 0 ? "" : "bg-opacity-40"
              } w-2 h-2 bg-white rounded-xl `}
            ></div>
            <div
              className={`${
                active === 1 ? "" : "bg-opacity-40"
              } w-2 h-2 bg-white rounded-xl `}
            ></div>
            <div
              className={`${
                active === 2 ? "" : "bg-opacity-40"
              } w-2 h-2 bg-white rounded-xl `}
            ></div>
          </div>
        </div>

        <div className="w-11/12 rounded-lg overflow-hidden mx-auto md:hidden">
          {
            Data.map((set) => (
              <div>
                <div className="flex flex-row  border-b-2 border-gray-100 bg-gray-50">
                  <div className="w-3/6 text-left text-sm font-medium  py-3 pl-4">
                    {" "}
                    {set.title}
                  </div>
                </div>
                {
                  set.list.map((e) => {
                    return (
                      <div className="flex flex-row py-3  border-b-2 border-gray-100 bg-white">
                        <div className="w-4/5 text-left text-sm font-semibold    flex flex-row space-x-1 items-center">
                          {" "}
                          <div className="pl-4"> {e.title}</div>{" "}
                          <div>
                            <img
                              src="https://d2lxkizvrhu4im.cloudfront.net/icons/help_icon.svg"
                              alt="help icon"
                              className="hidden md:block m-0"
                            />
                          </div>
                        </div>
                        <div
                          className={`${
                            active === 0 ? "flex" : "hidden"
                          } flex-row w-1/5  flex justify-end   px-4`}
                        >
                          {" "}
                          {e.isLockdown ? (
                            <img
                              src="https://d2lxkizvrhu4im.cloudfront.net/icons/check_icon_orange.svg"
                              alt="tick"
                              className="m-0 w-4"
                            />
                          ) : (
                            <img
                              src="https://d2lxkizvrhu4im.cloudfront.net/icons/cancel_mark_icon.svg"
                              alt="cancel"
                              className="m-0 w-4"
                            />
                          )}
                        </div>
                        <div
                          className={`${
                            active === 1 ? "flex" : "hidden"
                          } flex-row w-1/5 flex  justify-end   px-4`}
                        >
                          {" "}
                          {e.isWebApp ? (
                            <img
                              src="https://d2lxkizvrhu4im.cloudfront.net/icons/check_icon_orange.svg"
                              alt="tick"
                              className="m-0 w-4"
                            />
                          ) : (
                            <img
                              src="https://d2lxkizvrhu4im.cloudfront.net/icons/cancel_mark_icon.svg"
                              alt="cancel"
                              className="m-0 w-4"
                            />
                          )}
                        </div>
                        <div
                          className={`${
                            active === 2 ? "flex" : "hidden"
                          } flex-row w-1/5  flex  justify-end  px-4`}
                        >
                          {" "}
                          {e.isExtension ? (
                            <img
                              src="https://d2lxkizvrhu4im.cloudfront.net/icons/check_icon_orange.svg"
                              alt="tick"
                              className="m-0 w-4"
                            />
                          ) : (
                            <img
                              src="https://d2lxkizvrhu4im.cloudfront.net/icons/cancel_mark_icon.svg"
                              alt="cancel"
                              className="m-0 w-4"
                            />
                          )}
                        </div>
                      </div>
                    );
                  })
                }
              </div>
            ))
          }
        </div>

        {
          Data.map((set, index) => {
            return (
              <>
                {/* desktop view */}
                <div
                  key={index}
                  className="hidden md:flex flex-col justify-center text-center w-10/12 mx-auto lg:w-3/5"
                >
                  <div className="flex flex-col w-full bg-white rounded-xl overflow-hidden mt-10 shadow-lg">
                    <div className="hidden md:flex flex-row font-semibold border-b-2 border-gray-100  ">
                      <div className="w-3/6 text-left text-base font-semibold  py-3 pl-10">
                        {" "}
                        {set.title}
                      </div>
                      <div className=" w-1/6 text-sm flex flex-row justify-center items-center md:border-l-2  md:borer-gray-100 py-3 space-x-2">
                        {" "}
                        <img
                          src="https://d2lxkizvrhu4im.cloudfront.net/icons/bulb_on_icon.svg"
                          alt="bulb icon"
                          className="h-3  m-0"
                        />{" "}
                        <span> Lockdown</span>
                      </div>
                      <div className=" w-1/6 text-sm flex flex-row justify-center items-center py-3  md:border-l-2  md:borer-gray-100 space-x-2">
                        {" "}
                        <img
                          src="https://d2lxkizvrhu4im.cloudfront.net/icons/broadcast_icon.svg"
                          alt="bulb icon"
                          className="h-3  m-0"
                        />{" "}
                        {index === 2 ? (
                          <span>Web-App</span>
                        ) : (
                          <span>{t('platform')}</span>
                        )}
                      </div>
                      <div className=" w-1/6 text-sm flex flex-row justify-center items-center py-3  md:border-l-2  md:borer-gray-100 space-x-2">
                        {" "}
                        <img
                          src="https://d2lxkizvrhu4im.cloudfront.net/icons/broadcast_icon.svg"
                          alt="bulb icon"
                          className="h-3  m-0"
                        />{" "}
                        <span> Extension</span>
                      </div>
                    </div>
                    {set.list.map((e) => (
                      <div className="flex flex-row  border-b-2 border-gray-100">
                        <div className="w-3/6 text-left text-sm font-medium  py-3 pl-10">
                          {" "}
                          {e.title}
                        </div>
                        <div
                          className={`${
                            !breakpoints.md
                              ? "flex"
                              : state[0].state
                              ? "flex"
                              : "hidden"
                          }  w-2/5 md:w-1/6 flex flex-row justify-center py-3 md:border-l-2  md:borer-gray-100`}
                        >
                          {" "}
                          {e.isLockdown ? (
                            <img
                              src="https://d2lxkizvrhu4im.cloudfront.net/icons/check_icon_orange.svg"
                              alt="tick"
                              className="m-0 w-4"
                            />
                          ) : (
                            <img
                              src="https://d2lxkizvrhu4im.cloudfront.net/icons/cancel_mark_icon.svg"
                              alt="cancel"
                              className="m-0 w-4"
                            />
                          )}
                        </div>
                        <div
                          className={`${
                            !breakpoints.md
                              ? "flex"
                              : state[1].state
                              ? "flex"
                              : "hidden"
                          }  w-2/5 md:w-1/6 flex flex-row justify-center py-3 md:border-l-2  md:borer-gray-100`}
                        >
                          {" "}
                          {e.isWebApp ? (
                            <img
                              src="https://d2lxkizvrhu4im.cloudfront.net/icons/check_icon_orange.svg"
                              alt="tick"
                              className="m-0 w-4"
                            />
                          ) : (
                            <img
                              src="https://d2lxkizvrhu4im.cloudfront.net/icons/cancel_mark_icon.svg"
                              alt="cancel"
                              className="m-0 w-4"
                            />
                          )}
                        </div>
                        <div
                          className={`${
                            !breakpoints.md
                              ? "flex"
                              : state[1].state
                              ? "flex"
                              : "hidden"
                          } flex-row w-2/5 md:w-1/6 flex  justify-center py-3 md:border-l-2  md:borer-gray-100`}
                        >
                          {" "}
                          {e.isExtension ? (
                            <img
                              src="https://d2lxkizvrhu4im.cloudfront.net/icons/check_icon_orange.svg"
                              alt="tick"
                              className="m-0 w-4"
                            />
                          ) : (
                            <img
                              src="https://d2lxkizvrhu4im.cloudfront.net/icons/cancel_mark_icon.svg"
                              alt="cancel"
                              className="m-0 w-4"
                            />
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </>
            );
          })
        }
        <img
          src="https://d2lxkizvrhu4im.cloudfront.net/images/dots_4.svg"
          alt="dots"
          className="absolute bottom-32 -left-20 transform rotate-180"
        />
        <img
          src="https://d2lxkizvrhu4im.cloudfront.net/images/dots_4.svg"
          alt="dots"
          className="absolute bottom-0 right-0"
        />
      </div>
    </>
  );
};

export default AutomatedLiveComponent;

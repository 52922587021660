import * as React from "react";

import Layout from "../components/layout/layout";
import ProductLockdownBrowserComponent from "../components/product-page/product_lockdown_browser";
import Seo from "../components/seo";
import { useTranslation } from 'react-i18next';

const ProductLockdownBrowser = () => {
  const {t} = useTranslation();
  return (
    <>
      <Seo
        title={t('lockdown_browser')}
        description={t('prevent_your_candidates_from_accessing_web')}
      />
      <Layout>
        <ProductLockdownBrowserComponent />  
      </Layout>
    </>
  )
}

export default ProductLockdownBrowser

import React from "react";

import Ready from "../ready/ready";
import LockdownFunctions from "../product/lockdown_functions";
import Subscribe from "../subscribe/subscribe_2";
import TopBanner from "../top_banner/product_top_banner";
import AutomatedLiveComponent from "../automatedLive/automatedLive";
import { useTranslation } from 'react-i18next';

const ProductLockdownBrowser = () => {
  const { t } = useTranslation();
  const LockdownFunctionData = [
    {
      iconSrc: "https://d2lxkizvrhu4im.cloudfront.net/icons/Force Full Screen.svg",
      title: `${t('force_full_screen')}`,
      description: `${t('the_assessment_opens_and_runs')}`,
    },
    {
      iconSrc: "https://d2lxkizvrhu4im.cloudfront.net/icons/Only One Screen.svg",
      title: `${t('other_screen_detected')}`,
      description: `${t('the_candidate_is_forced_to_unplug')}`,
    },
    {
      iconSrc: "https://d2lxkizvrhu4im.cloudfront.net/icons/Disable New Tabs.svg",
      title: `${t('new_tab_inaccessible')}`,
      description: `${t('the_system_prevents_the_candidate')}`,
    },
    {
      iconSrc: "https://d2lxkizvrhu4im.cloudfront.net/icons/Close Open Tabs.svg",
      title: `${t('browser_cleaned_upstream')}`,
      description: `${t('before_the_test_starts_the_system_closes')}`,
    },
    {
      iconSrc: "https://d2lxkizvrhu4im.cloudfront.net/icons/Disable Printing.svg",
      title: `${t('cache_cleaned_downstream')}`,
      description:`${t('all_traces_of_the_assessment')}`,
    },
    {
      iconSrc: "https://d2lxkizvrhu4im.cloudfront.net/icons/Disable Clipboard.svg",
      title: `${t('printer_disabled')}`,
      description:`${t('printing_and_downloading_are_not_permitted')}`,
    },
    {
      iconSrc: "https://d2lxkizvrhu4im.cloudfront.net/icons/Clear Cache.svg",
      title: `${t('extension_disabled')}`,
      description:`${t('the_use_of_other_extensions_is_not_permitted')}`,
    },
    {
      iconSrc: "https://d2lxkizvrhu4im.cloudfront.net/icons/Disable Right Click.svg",
      title: `${t('right_click_disabled')}`,
      description:`${t('by_disabling_right_click')}`,
    },
    {
      iconSrc: "https://d2lxkizvrhu4im.cloudfront.net/icons/Prevent Re-Entry.svg",
      title: `${t('disabled_keyboard_shortcuts')}`,
      description:`${t('by_disabling_keyboard_shortcuts')}`,
    },
  ];
  return (
    <>
      <div className={`conatiner font-sans`}>
        <TopBanner
          pageTitle="Lockdown Browser"
          title={t('applicant_browser_lock')}
          description={t('mereos_lockdown_browser')}
          imageSrc="https://d2lxkizvrhu4im.cloudfront.net/images/dog_sitting _next_to _lock.svg"
          leftButton={{
            text: `${t("free_trials")}`,
            href: "/free-trial",
          }}
          rightButton={{
            text: `Demo >`,
            href: "/demo",
          }}
        />

        {/* sub Container */}
        <div className={`mx-auto w-11/12 lg:w-5/6 `}>
          <div className="md:my-24">
            <LockdownFunctions
              title={t('a_sophisticated_lockdown_browser')}
              subTitle={t('with_mereos_the_following_lock_settings')}
              Data={LockdownFunctionData}
            />
          </div>
        </div>

        <div className="md:my-32">
          <AutomatedLiveComponent />
        </div>
        <div className={`mx-auto w-11/12 lg:w-5/6 `}>
          <div className="my-20">
            <Ready />
          </div>
        </div>
        <Subscribe />
      </div>
    </>
  );
};

export default ProductLockdownBrowser;
